import findIndex from "lodash/findIndex";
import uniqueId from "lodash/uniqueId";
import { action, computed, flow, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { PrimaryFormResponse, revisedFormService, SecondaryFormResponse } from "services";
import { ApiKeyFields, PrimaryUserForm, SecondaryFormNames, Tracks, Works } from "types";
import {
  Comment,
  DigitalReleaseFormPayload,
  DistributionInfoFormCC,
  DistributionInfoFormPayload,
  DistributionLegalFormCC,
  DistributionLegalFormPayload,
  Edit,
  PatchCurrentCommentForm,
  PrimaryFormNames,
  ReleaseArtists,
} from "types/revisedForms";
import { convertCamelToSnakeCase } from "utils";

import { CommentsStore } from "./CommentsStore";
import { extractComments } from "./helpers";
import { ReleaseArtistsStore } from "./ReleaseArtistsStore";
import { ReleaseCommentStore } from "./ReleaseCommentStore";
import { TracksStore } from "./TracksStore";
import { WorksStore } from "./WorksStore";

export class RevisedFormStore {
  @observable distributionInfo: DistributionInfoFormPayload | null = null;
  @observable distributionLegal: DistributionLegalFormPayload | null = null;
  @observable release: DigitalReleaseFormPayload | null = null;
  @observable formEdits: Edit[] = [];
  comments: CommentsStore;
  releaseArtists: ReleaseArtistsStore;
  works: WorksStore;
  tracks: TracksStore;
  releaseComments: ReleaseCommentStore;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.comments = new CommentsStore(rootStore);
    this.releaseArtists = new ReleaseArtistsStore(rootStore);
    this.works = new WorksStore(rootStore);
    this.tracks = new TracksStore(rootStore);
    this.releaseComments = new ReleaseCommentStore(rootStore);
  }

  private prepareFormData<T extends { [ApiKeyFields.comments]: Comment[]; [ApiKeyFields.edits]: Edit[] }>(data: T) {
    const { comments, edits, ...formData } = data;
    this.comments.primaryComments = comments;
    this.formEdits = edits;
    return formData;
  }

  processFormResponse<T extends PrimaryFormNames>(primaryFormName: T, response: PrimaryFormResponse<T>) {
    if (ApiKeyFields.revisedForms in response) {
      if (!response.revisedForms.length) return;
      const formData = this.prepareFormData(response.revisedForms?.[0]);

      if (primaryFormName === PrimaryFormNames.DistributionInfos) {
        this.distributionInfo = formData as DistributionInfoFormCC;
      } else if (primaryFormName === PrimaryFormNames.DistributionLegals) {
        this.distributionLegal = formData as DistributionLegalFormCC;
      }
    } else if (ApiKeyFields.revisedForm in response) {
      const { revisedForm } = response;
      if (primaryFormName === PrimaryFormNames.Releases) {
        this.release = this.prepareFormData(revisedForm);
      }
    }
  }

  @action
  updateFieldEdits = (editKeys: ApiKeyFields[]) => {
    const user = this.rootStore.userStore.user!;
    const updatedEdits = [...this.formEdits];

    editKeys.forEach((key) => {
      const index = findIndex(updatedEdits, { fieldName: key });
      if (index !== -1) {
        updatedEdits[index] = { ...updatedEdits[index], user, isAdmin: user.isAdmin };
      } else {
        updatedEdits.push({
          id: uniqueId(),
          fieldName: key,
          fieldType: " ",
          isAdmin: user.isAdmin,
          user: user,
        });
      }
    });

    this.formEdits = updatedEdits;
  };

  @flow.bound
  *retrievePrimaryForm<T extends PrimaryFormNames>(
    userId: string | null,
    primaryFormName: T,
  ): MobxGenerator<PrimaryFormResponse<T>> {
    const response = yield revisedFormService.getPrimaryForm({ userId, primaryFormName });
    this.rootStore.revisedFormStore.processFormResponse(primaryFormName, response);
  }

  @flow.bound
  *retrievePrimaryFormId<P extends PrimaryFormNames>(
    userId: string | null,
    primaryFormName: P,
    primaryFormId: string,
  ): MobxGenerator<PrimaryFormResponse<P>> {
    const response = yield revisedFormService.getPrimaryFormId({
      userId,
      primaryFormName: primaryFormName,
      primaryFormId,
    });
    this.rootStore.revisedFormStore.processFormResponse(primaryFormName as P, response);
  }

  @flow.bound
  *retrieveUserIdRevisedFormLvl1<T extends PrimaryFormNames>(
    userId: string,
    primaryFormName: T,
  ): MobxGenerator<PrimaryFormResponse<T>> {
    const response = yield revisedFormService.getPrimaryUserIdForm({ userId, primaryFormName });
    this.rootStore.revisedFormStore.processFormResponse(primaryFormName, response);
  }

  patchUserIdRevisedFormIdLvl1 = async (urlData: PrimaryUserForm, payload: DistributionInfoFormPayload) => {
    await revisedFormService.patchPrimaryUserIdFormId(urlData, payload);
    await this.rootStore.revisedFormStore.updateFieldEdits(Object.keys(convertCamelToSnakeCase(payload)) as ApiKeyFields[]);
  };

  @flow.bound
  *retrievePrimaryRevisedUserIdFormId<T extends PrimaryFormNames>(
    userId: string,
    primaryFormName: T,
    primaryFormId: string,
  ): MobxGenerator<PrimaryFormResponse<T>> {
    const response = yield revisedFormService.getPrimaryUserIdFormId({ userId, primaryFormName, primaryFormId });
    this.rootStore.revisedFormStore.processFormResponse(primaryFormName as T, response);
  }

  @flow.bound
  *retrieveDistrInfos(userId: string | null) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield Promise.all([
        ...(userId ? [this.rootStore.qualificationStore.retrieveQualificationFormById(userId)] : []),
        this.retrievePrimaryForm(userId, PrimaryFormNames.DistributionInfos),
      ]);
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @flow.bound
  *retrieveDistrLegal(userId: string | null) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield Promise.all([
        ...(userId ? [this.rootStore.qualificationStore.retrieveQualificationFormById(userId)] : []),
        this.retrievePrimaryForm(userId, PrimaryFormNames.DistributionInfos),
      ]);
      yield this.retrievePrimaryForm(userId, PrimaryFormNames.DistributionLegals);
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  retrieveRelease = async (userId: string | null, primaryFormId: string) => {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      this.resetRevisedForm();
      await this.retrievePrimaryFormId(userId, PrimaryFormNames.Releases, primaryFormId);
      await this.retrieveReleaseArtistsList(userId, primaryFormId);
      await this.retrieveWorksList(userId, primaryFormId);
      await this.retrieveTracksList(userId, primaryFormId);
    } finally {
      setTimeout(() => this.rootStore.uiStore.resetLoading());
    }
  };

  @flow.bound
  *retrieveReleaseArtistsList<P extends PrimaryFormNames, S extends SecondaryFormNames>(
    userId: string | null,
    primaryFormId: string,
  ): MobxGenerator<SecondaryFormResponse<P, S>> {
    try {
      this.rootStore.revisedFormStore.releaseArtists.setLoading(true);
      const response = yield revisedFormService.getSecondaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseArtists,
        primaryFormId,
      });
      if ("revisedForms" in response) {
        this.comments.secondaryComments = [
          ...this.comments.secondaryComments,
          ...extractComments(response.revisedForms as ReleaseArtists[]),
        ];
        this.rootStore.revisedFormStore.releaseArtists.releaseArtistsList = response.revisedForms as ReleaseArtists[];
      }
    } finally {
      this.rootStore.revisedFormStore.releaseArtists.setLoading(false);
    }
  }

  @flow.bound
  *retrieveWorksList<P extends PrimaryFormNames, S extends SecondaryFormNames>(
    userId: string | null,
    primaryFormId: string,
  ): MobxGenerator<SecondaryFormResponse<P, S>> {
    try {
      this.rootStore.revisedFormStore.works.setLoading(true);
      const response = yield revisedFormService.getSecondaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseWorks,
        primaryFormId,
      });
      if ("revisedForms" in response) {
        this.comments.secondaryComments = [
          ...this.comments.secondaryComments,
          ...extractComments(response.revisedForms as Works[]),
        ];
        this.rootStore.revisedFormStore.works.worksList = response.revisedForms as Works[];
      }
    } finally {
      this.rootStore.revisedFormStore.works.setLoading(false);
    }
  }

  @flow.bound
  *retrieveTracksList<P extends PrimaryFormNames, S extends SecondaryFormNames>(
    userId: string | null,
    primaryFormId: string,
  ): MobxGenerator<SecondaryFormResponse<P, S>> {
    try {
      this.rootStore.revisedFormStore.tracks.setLoading(true);
      const response = yield revisedFormService.getSecondaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
        primaryFormId,
      });
      if ("revisedForms" in response) {
        this.comments.secondaryComments = [
          ...this.comments.secondaryComments,
          ...extractComments(response.revisedForms as Tracks[]),
        ];
        this.rootStore.revisedFormStore.tracks.tracksList = response?.revisedForms as Tracks[];
      }
    } finally {
      this.rootStore.revisedFormStore.tracks.setLoading(false);
    }
  }

  @action
  updateComments(commentFieldName: string, comment: PatchCurrentCommentForm) {
    this.comments.primaryComments = this.comments.primaryComments.map((c) =>
      c.fieldName === commentFieldName ? { ...c, ...comment } : c,
    );
  }

  @action
  deleteDistributionLegalFile = (fileKey: string) => {
    const formData = this.distributionLegal;
    if (formData) {
      this.distributionLegal = { ...formData, [fileKey]: null };
    }
  };

  @computed
  get formId() {
    return this.distributionInfo?.id || this.release?.id;
  }

  @computed
  get commentsLvl1FieldKeys() {
    return this.comments.primaryComments.map(({ fieldName }) => fieldName);
  }

  @computed
  get editsLvl1() {
    return this.formEdits;
  }

  @action.bound
  resetRevisedForm = () => {
    this.distributionInfo = null;
    this.distributionLegal = null;
    this.release = null;
    this.releaseArtists.releaseArtistsList = [];
    this.works.worksList = [];
    this.tracks.tracksList = [];
    this.comments.primaryComments = [];
    this.comments.secondaryComments = [];
    this.comments.tertiaryComments = [];
    this.formEdits = [];
    this.rootStore.qualificationStore.resetQualificationForm();
  };
}
