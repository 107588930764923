import { action, makeObservable } from "mobx";
import { RootStore } from "stores";
import { TableStore } from "stores/_tableStore";

import { GridSortModel } from "@mui/x-data-grid";

import { userService } from "services";
import { UserArtist, UserListParams, UserListResponse } from "types";

import { Filters, getSortingData, processFilters } from "./utils";

export class AdminsTable extends TableStore<UserListResponse, UserArtist, UserListParams> {
  constructor(public rootStore: RootStore) {
    super();
    makeObservable(this);
  }

  getTableData = async (defaultSortModel?: GridSortModel) => {
    await this.retrieveTableData({
      service: userService.getUsersList,
      extractData: ({ users, numUsers }: UserListResponse) => ({
        rows: users,
        totalCount: numUsers,
      }),
      prepareGenericParams: (data) => {
        const { paginationModel, sortModel, filters } = data;
        const user_offset = paginationModel.page * paginationModel.pageSize;
        const user_limit = paginationModel.pageSize;
        return {
          user_is_admin: true,
          user_offset,
          user_limit,
          ...getSortingData(sortModel),
          ...processFilters(filters as unknown as Filters),
        };
      },
      defaultSortModel,
    });
  };

  @action
  changeUserIsActive = (id: string, isActive: boolean) => {
    const users = this.rows;
    if (users.length) {
      this.rows = users.map((user) => (user.id === id ? { ...user, isActive } : user));
    }
  };
}
