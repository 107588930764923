import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import TracksTemplate from "assets/docs/tracks_template.xlsx";
import { DropZoneField, FormHelperTextContainerStyled, HintLinkButtonStyled, Modal } from "components";
import BulkInviteResultModal, { BulkInviteResult } from "components/BulkInviteResultModal";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { ApiKeyFields, PrimaryFormNames, SecondaryFormNames, TertiaryFormNames } from "types";
import { delay, onDownloadFile, readFileAsArrayBuffer } from "utils";

import { defaultValues } from "./constants";
import { processTrackTemplate, TrackTemplateData } from "./helpers";
import { HelperTextContainerStyled, SummaryResultContainerStyled } from "./styled";
import { FailureValue } from "./types";

const AddBulkTracks: FC<BaseModalProps> = ({ closeModal }) => {
  const {
    uiStore: { resetLoading, setOverlayLoading, setLoadingText, openModal },
  } = useStores();
  const form = useForm({
    defaultValues,
  });
  const { control, watch, handleSubmit } = form;
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const setLoadingProgress = (successCount: number) => setLoadingText(`Processed track(s): ${successCount}`);

  const processTracksData = async (tracksData: TrackTemplateData[]) => {
    let successCount = 0;
    const failureKeyArray: FailureValue[] = [];

    for (const { artists, contributors, ...form } of tracksData) {
      try {
        const trackErrors: FailureValue = { track: form[ApiKeyFields.title], artists: [], contributors: [] };

        const {
          revisedForm: { id: trackId },
        } = await revisedFormService.postSecondaryForm(
          {
            userId,
            primaryFormName: PrimaryFormNames.Releases,
            secondaryFormName: SecondaryFormNames.ReleaseTracks,
            primaryFormId: releaseId!,
          },
          form,
        );
        successCount++;
        setLoadingProgress(successCount);

        for (const artist of artists) {
          try {
            await delay(1000);
            await revisedFormService.postTertiaryForm(
              {
                userId,
                [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
                [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
                [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseTrackArtists,
                [ApiKeyFields.primaryFormId]: releaseId!,
                [ApiKeyFields.secondaryFormId]: trackId!,
              },
              artist,
            );
          } catch {
            trackErrors.artists.push(artist[ApiKeyFields.artistName]);
          }
        }

        for (const contributor of contributors) {
          try {
            await delay(1000);
            await revisedFormService.postTertiaryForm(
              {
                userId,
                [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
                [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
                [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseContributors,
                [ApiKeyFields.primaryFormId]: releaseId!,
                [ApiKeyFields.secondaryFormId]: trackId!,
              },
              contributor,
            );
          } catch {
            trackErrors.contributors.push(contributor[ApiKeyFields.artistName]);
          }
        }

        if (trackErrors.contributors.length || trackErrors.artists.length) {
          failureKeyArray.push(trackErrors);
        }
      } catch (error) {
        failureKeyArray.push({ track: form[ApiKeyFields.title], artists: [], contributors: [] });
      }
    }

    return { successCount, failureKeyArray };
  };

  const onSubmit = handleSubmit(async ({ template }) => {
    if (!template || !releaseId) return;

    let result: BulkInviteResult<FailureValue> = { successCount: 0, failureKeyArray: [] };

    try {
      closeModal();
      setOverlayLoading(true, "Uploading the file...");

      const data = await readFileAsArrayBuffer(template);
      const templateData = processTrackTemplate(data);
      result = await processTracksData(templateData);

      openModal({
        component: (modalProps) => (
          <BulkInviteResultModal<FailureValue> {...modalProps} {...result} title="Bulk of Tracks result">
            {({ successCount, failureKeyArray, haveErrors }) => (
              <>
                <Typography variant="body1">
                  <b>{successCount}</b> track(s) have been successfully created.
                </Typography>
                {haveErrors ? (
                  <Typography variant="body1" whiteSpace="pre-line">
                    The system was unable to upload the following entity(s):{" "}
                    {failureKeyArray.map(({ track, artists, contributors }, index) => (
                      <SummaryResultContainerStyled key={index}>
                        <b>{track}</b>
                        {artists.length ? (
                          <span>
                            Artists: <b>{artists.map((a) => a).join(", ")}</b>
                          </span>
                        ) : null}
                        {contributors.length ? (
                          <span>
                            Contributors: <b>{contributors.map((a) => a).join(", ")}</b>
                          </span>
                        ) : null}
                      </SummaryResultContainerStyled>
                    ))}
                  </Typography>
                ) : null}
              </>
            )}
          </BulkInviteResultModal>
        ),
        PaperProps: { sx: { width: result.failureKeyArray.length ? "648px" : "448px" } },
        onClose: () => {
          window.location.reload();
        },
      });
    } finally {
      resetLoading();
    }
  });

  const handleDownloadTemplate = () => onDownloadFile({ title: "trackTemplate", file: TracksTemplate });

  const watchTemplate = watch(ApiKeyFields.template);

  return (
    <>
      <Modal.ModalTitle title="Add the Bulk of Tracks" closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <FormProvider {...form}>
          <FormGridFieldItem
            fieldName={ApiKeyFields.template}
            label="Select File"
            helperText={
              <FormHelperTextContainerStyled>
                <Typography variant="body1" color="text.label">
                  You can upload tracks metadata in an Excel spreadsheet format.
                </Typography>
                <Typography variant="body1" color="text.label">
                  Please download and use file:
                </Typography>
                <HelperTextContainerStyled>
                  <HintLinkButtonStyled variant="link" size="small" onClick={handleDownloadTemplate}>
                    the template (.XSLX)
                  </HintLinkButtonStyled>
                </HelperTextContainerStyled>
              </FormHelperTextContainerStyled>
            }
            isOptionalField
          >
            {(field) => (
              <DropZoneField
                {...field}
                control={control}
                maxSizeLimit={[100, "MB"]}
                dropFileText="Drop your spreadsheet here"
                options={{
                  multiple: false,
                  accept: {
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                  },
                }}
              />
            )}
          </FormGridFieldItem>
        </FormProvider>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" onClick={onSubmit} disabled={!watchTemplate}>
          Add
        </Button>
      </Modal.ModalActions>
    </>
  );
};

export default observer(AddBulkTracks);
