import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { PrimaryFormNames, ReleaseCommentCount, ReleaseStatus, SecondaryFormNames, TertiaryFormNames } from "types";

export const useFetchComments = () => {
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const {
    revisedFormStore: {
      release,
      releaseComments: { setCommentState, resetCommentsTotal },
    },
  } = useStores();

  const safeFetchSecondaryComments = async (secondaryFormName: SecondaryFormNames): Promise<ReleaseCommentCount> => {
    try {
      return await revisedFormService.getSecondaryCommentList({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        primaryFormId: releaseId!,
        secondaryFormName,
      });
    } catch (error) {
      return { numCommentsForUser: 0, numCommentsForAdmin: 0 };
    }
  };

  const safeFetchTertiaryComments = async (
    secondaryFormName: SecondaryFormNames,
    tertiaryFormName: TertiaryFormNames,
  ): Promise<ReleaseCommentCount> => {
    try {
      return await revisedFormService.getTertiaryCommentList({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        primaryFormId: releaseId!,
        secondaryFormName,
        tertiaryFormName,
      });
    } catch (error) {
      return { numCommentsForUser: 0, numCommentsForAdmin: 0 };
    }
  };

  const calculateTotalComments = (comments: ReleaseCommentCount[]): ReleaseCommentCount => {
    return comments.reduce(
      (acc, comment) => {
        acc.numCommentsForUser += comment.numCommentsForUser;
        acc.numCommentsForAdmin += comment.numCommentsForAdmin;
        return acc;
      },
      { numCommentsForUser: 0, numCommentsForAdmin: 0 },
    );
  };

  const fetchWorksComments = useCallback(async () => {
    if (!releaseId) return;
    const works = await safeFetchSecondaryComments(SecondaryFormNames.ReleaseWorks);
    const worksRevisedComments = await Promise.all([
      safeFetchTertiaryComments(SecondaryFormNames.ReleaseWorks, TertiaryFormNames.ReleaseSongwriters),
      safeFetchTertiaryComments(SecondaryFormNames.ReleaseWorks, TertiaryFormNames.ReleasePublishers),
    ]);
    const allWorksComments = [works, ...worksRevisedComments.flat()];
    const totalWorksComments: ReleaseCommentCount = calculateTotalComments(allWorksComments);
    setCommentState(SecondaryFormNames.ReleaseWorks, totalWorksComments);
  }, [releaseId]);

  const fetchTracksComments = useCallback(async () => {
    if (!releaseId) return;
    const tracks = await safeFetchSecondaryComments(SecondaryFormNames.ReleaseTracks);
    const tracksRevisedComments = await Promise.all([
      safeFetchTertiaryComments(SecondaryFormNames.ReleaseTracks, TertiaryFormNames.ReleaseTrackArtists),
      safeFetchTertiaryComments(SecondaryFormNames.ReleaseTracks, TertiaryFormNames.ReleaseContributors),
    ]);
    const allTracksComments = [tracks, ...tracksRevisedComments.flat()];
    const totalTracksComments: ReleaseCommentCount = calculateTotalComments(allTracksComments);
    setCommentState(SecondaryFormNames.ReleaseTracks, totalTracksComments);
  }, [releaseId]);

  const retrieveCommentState = async (status?: ReleaseStatus | null) => {
    if (status === (ReleaseStatus.Draft || ReleaseStatus.Released) || !release?.status) return;
    try {
      await Promise.all([fetchWorksComments(), fetchTracksComments()]);
    } finally {
      null;
    }
  };

  const updateCommentState = async (commentsType: SecondaryFormNames) => {
    switch (commentsType) {
      case SecondaryFormNames.ReleaseWorks:
        return fetchWorksComments();
      case SecondaryFormNames.ReleaseTracks:
        return fetchTracksComments();
      case SecondaryFormNames.ReleaseArtists:
        return;
    }
  };

  return { fetchWorksComments, fetchTracksComments, retrieveCommentState, resetCommentsTotal, updateCommentState };
};
