import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { BlobDownloadOptions, BlockBlobClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";
import { useStores } from "stores";

import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { ApiKeyFields, FileOptionProps, PrimaryFormNames, SecondaryFormNames } from "types";
import { onDownloadFile } from "utils";

interface SecondaryBlob {
  field_name: ApiKeyFields;
  primaryFormId: string;
  secondaryFormId: string;
}

export const useReleaseService = () => {
  const { userId = null } = useParams<ReleaseUrlParams>();
  const {
    revisedFormStore: { release },
    uiStore: { setProcessingLoading, onUploadingProgress, resetLoading },
  } = useStores();

  const getCurrentReleaseBlobLvl1 = useCallback(
    async (field_name: ApiKeyFields, options: FileOptionProps) => {
      if (!release?.id) return;
      const { id: primaryFormId } = release;

      try {
        const { acceptedFiles, blob_action } = options;
        setProcessingLoading(true, "Uploading the file…");

        const { blobFieldSasUrl } = await revisedFormService.getPrimaryBlob({
          userId: null,
          primaryFormName: PrimaryFormNames.Releases,
          primaryFormId,
          field_name,
          blob_action,
        });

        if (blob_action === "update" && acceptedFiles) {
          const file = acceptedFiles[0];
          const blockBlobClient = new BlockBlobClient(blobFieldSasUrl);
          const blobOptions: BlockBlobParallelUploadOptions = {
            blobHTTPHeaders: { blobContentType: file.type },
            onProgress: (ev) => onUploadingProgress(ev.loadedBytes, file.size),
          };
          await blockBlobClient.uploadData(file, blobOptions);
          await revisedFormService.patchPrimaryBlob(
            {
              userId: null,
              primaryFormName: PrimaryFormNames.Releases,
              primaryFormId,
            },
            field_name,
          );
        }
      } finally {
        resetLoading();
      }
    },
    [release?.id],
  );

  const currentReleaseBlobActionLvl2 = async (params: SecondaryBlob, options: FileOptionProps) => {
    const { primaryFormId, secondaryFormId, field_name } = params;
    try {
      const { acceptedFiles, blob_action } = options;
      setProcessingLoading(true, "Uploading the file…");

      const { blobFieldSasUrl } = await revisedFormService.getSecondaryBlob({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        primaryFormId,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
        secondaryFormId,
        field_name,
        blob_action,
      });

      if (blob_action === "update" && acceptedFiles) {
        const file = acceptedFiles[0];
        const blockBlobClient = new BlockBlobClient(blobFieldSasUrl);
        const blobOptions: BlockBlobParallelUploadOptions = {
          blobHTTPHeaders: { blobContentType: file.type },
          onProgress: (ev) => onUploadingProgress(ev.loadedBytes, file.size),
        };
        await blockBlobClient.uploadData(file, blobOptions);
        await revisedFormService.patchSecondaryBlob(
          {
            userId: null,
            primaryFormName: PrimaryFormNames.Releases,
            secondaryFormName: SecondaryFormNames.ReleaseTracks,
            primaryFormId,
            secondaryFormId,
          },
          field_name,
        );
      } else if (blob_action === "get") {
        const blobClient = new BlockBlobClient(blobFieldSasUrl);
        const properties = await blobClient.getProperties();
        const totalBytes = properties.contentLength;
        const downloadOptions: BlobDownloadOptions = {
          onProgress: (ev) => onUploadingProgress(ev.loadedBytes, totalBytes!),
        };
        const response = await blobClient.download(void 0, void 0, downloadOptions);
        const blob = await response.blobBody;
        if (!blob) {
          throw new Error("Failed to download blob");
        }
        const file = window.URL.createObjectURL(blob);
        onDownloadFile({ title: blob.size.toString(), file, fileFormat: "wav" });
      }
    } finally {
      resetLoading();
    }
  };

  return {
    getCurrentReleaseBlobLvl1,
    currentReleaseBlobActionLvl2,
  };
};
