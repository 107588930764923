import { useCallback, useEffect } from "react";
import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useStores } from "stores";

import Typography from "@mui/material/Typography";

import { ConfirmModal, DndOrderIndex } from "components";
import { useFetchComments } from "components/ReleaseForm/Hooks";
import CopyEntity from "components/ReleaseForm/Shared/CopyEntity";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  Contributors,
  PrimaryFormNames,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
  TracksForm,
} from "types";

import AddEditContributor from "./AddEditContributor";

export const useContributors = (track: TracksForm) => {
  const {
    revisedFormStore: {
      tracks: { contributors, contributorsLoading },
    },
    uiStore: { setOverlayLoading, resetLoading, openModal },
  } = useStores();
  const { fetchTracksComments } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const { control, setValue, watch } = useFormContext<{ contributors: Contributors[] }>();
  const { fields } = useFieldArray({
    control,
    name: "contributors",
    keyName: "_id",
  });

  useEffect(() => {
    contributors && setValue("contributors", contributors, { shouldDirty: false });
  }, [contributors]);

  const handleContributorsArrayChange = (data: Contributors) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [data, ...fields];
    setValue("contributors", newArray, { shouldDirty: false });
  };

  const handleChangeRows = useCallback(
    async (rows: Contributors[], { afterId, currentItemId }: DndOrderIndex) => {
      const prevValues = [...watch("contributors")];
      try {
        setValue("contributors", rows, { shouldDirty: false });
        const requestParams: TertiaryFormId = {
          userId,
          [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
          [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
          [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseContributors,
          [ApiKeyFields.primaryFormId]: releaseId!,
          [ApiKeyFields.secondaryFormId]: track.id!,
          [ApiKeyFields.tertiaryFormId]: currentItemId,
        };
        await revisedFormService.reorderTertiaryForm(requestParams, { afterId });
      } catch {
        setValue("contributors", prevValues, { shouldDirty: false });
      }
    },
    [setValue, track],
  );

  const handleDeleteContributor = async (record: Contributors, closeModal: () => void) => {
    try {
      setOverlayLoading(true);
      const requestParams: TertiaryFormId = {
        userId,
        [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
        [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
        [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseContributors,
        [ApiKeyFields.primaryFormId]: releaseId!,
        [ApiKeyFields.secondaryFormId]: track.id!,
        [ApiKeyFields.tertiaryFormId]: record.id as string,
      };
      await revisedFormService.deleteTertiaryFormId(requestParams);
      await fetchTracksComments();
      closeModal();
      const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
      setValue("contributors", updatedArray, { shouldDirty: false });
    } finally {
      resetLoading();
    }
  };

  const handleAddContributor = () => {
    openModal({
      component: (modalProps) => (
        <AddEditContributor {...modalProps} track={track} successCallback={handleContributorsArrayChange} />
      ),
      fullWidth: true,
      maxWidth: "sm",
    });
  };

  const handleEditContributor = (record: Contributors) => {
    openModal({
      component: (modalProps) => (
        <AddEditContributor {...modalProps} track={track} record={record} successCallback={handleContributorsArrayChange} />
      ),
      fullWidth: true,
      maxWidth: "sm",
    });
  };

  const handleDeleteClick = (record: Contributors) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the contributor?"
          text={
            <Typography variant="body1">
              Remove <b>{record.artistName}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteContributor(record, modalProps.closeModal)}
        />
      ),
    });
  };

  const fetchContributor = useCallback(
    async ({ offset, limit }: { offset: number; limit: number }) => {
      try {
        const response = await revisedFormService.retrieveReleaseContributorsOptions(
          { userId: null, primaryFormId: releaseId! },
          { offset, limit, order_by_field_name: ApiKeyFields.artist_name },
        );
        return response.revisedForms;
      } catch {
        return [];
      }
    },
    [track],
  );

  const copyContributor = useCallback(
    async ({ value }: FieldValues, options: Contributors[]) => {
      if (!track.id || !releaseId) return;
      try {
        setOverlayLoading(true);
        const findRecord = options.find(({ id }) => id === value);
        if (findRecord) {
          const requestParams: TertiarySecondaryFormId = {
            userId: null,
            [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
            [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
            [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseContributors,
            [ApiKeyFields.primaryFormId]: releaseId!,
            [ApiKeyFields.secondaryFormId]: track.id,
          };
          const { revisedForm } = await revisedFormService.postTertiaryForm(requestParams, findRecord);
          handleContributorsArrayChange(revisedForm);
        }
      } finally {
        resetLoading();
      }
    },
    [track, fields],
  );

  const handleCopyContributor = () => {
    openModal({
      component: (modalProps) => (
        <CopyEntity
          {...modalProps}
          fetchFunction={fetchContributor}
          mapFunction={(record) => ({ label: record.artistName!, value: record.id! })}
          modalTitle="Copy Contributor"
          inputLabel="Select Contributor"
          onSubmitCb={copyContributor}
        />
      ),
      fullWidth: true,
    });
  };

  return {
    fields,
    contributorsLoading,
    handleContributorsArrayChange,
    handleCopyContributor,
    handleChangeRows,
    handleDeleteContributor,
    handleAddContributor,
    handleEditContributor,
    handleDeleteClick,
  };
};
