import { FC, ReactNode } from "react";
import styled from "@emotion/styled";
import InputNumber, { InputNumberProps } from "antd/lib/input-number";

type NumberInputStyledProps = {
  hideControlArrows?: boolean;
};

export const NumberInputStyled = styled(InputNumber, {
  shouldForwardProp: (prop) => !["hideControlArrows"].includes(prop),
})<NumberInputStyledProps>`
  width: 100%;
  &.ant-input-number-focused {
    border-width: 2px;
  }
  .ant-input-number-group-addon {
    background-color: ${({ theme }) => theme.palette.background.surface};
  }

  &.ant-input-number-borderless {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 0;
  }
  &.ant-input-number-disabled {
    color: ${({ theme }) => theme.palette.text.primary};
    padding-left: 11px;
  }
  &.ant-input-number-disabled .ant-input-number-input {
    cursor: default !important;
  }
  ${({ hideControlArrows }) => hideControlArrows && `.ant-input-number-handler-wrap { display: none }`}
`;

type AntNumberInputProps = InputNumberProps & NumberInputStyledProps;

const AntNumberInput: FC<AntNumberInputProps> = (props) => {
  return (
    <NumberInputStyled size="small" {...props}>
      {props.children as ReactNode}
    </NumberInputStyled>
  );
};

export default AntNumberInput;
