import { action, flow, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { revisedFormService } from "services";
import {
  Contributors,
  PrimaryFormNames,
  ReleaseArtists,
  ReleaseTrackWorks,
  SecondaryFormNames,
  TertiaryFormNames,
  Tracks,
} from "types";

import { extractComments } from "./helpers";

export class TracksStore {
  @observable tracksList: Tracks[] = [];

  @observable contributors: Contributors[] = [];
  @observable contributorsLoading: boolean = false;
  @observable artists: ReleaseArtists[] = [];
  @observable artistsLoading: boolean = false;
  @observable relatedWorks: ReleaseTrackWorks[] = [];
  @observable relatedWorksLoading: boolean = false;

  @observable isLoading: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @flow.bound
  *retrieveContributors(userId: string | null, primaryFormId: string, secondaryFormId: string) {
    try {
      this.contributorsLoading = true;
      const { revisedForms } = yield revisedFormService.getTertiaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
        tertiaryFormName: TertiaryFormNames.ReleaseContributors,
        primaryFormId,
        secondaryFormId,
      });
      this.rootStore.revisedFormStore.comments.tertiaryComments = [
        ...this.rootStore.revisedFormStore.comments.tertiaryComments,
        ...extractComments(revisedForms),
      ];
      this.contributors = revisedForms;
    } finally {
      this.contributorsLoading = false;
    }
  }

  @flow.bound
  *retrieveReleaseArtists(userId: string | null, primaryFormId: string, secondaryFormId: string) {
    try {
      this.artistsLoading = true;
      const { revisedForms } = yield revisedFormService.getTertiaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
        tertiaryFormName: TertiaryFormNames.ReleaseTrackWorks,
        primaryFormId,
        secondaryFormId,
      });
      this.rootStore.revisedFormStore.comments.tertiaryComments = [
        ...this.rootStore.revisedFormStore.comments.tertiaryComments,
        ...extractComments(revisedForms),
      ];
      this.relatedWorks = revisedForms;
    } finally {
      this.artistsLoading = false;
    }
  }

  @flow.bound
  *retrieveReleaseRelatedWorks(userId: string | null, primaryFormId: string, secondaryFormId: string) {
    try {
      this.relatedWorksLoading = true;
      const { revisedForms } = yield revisedFormService.getTertiaryForm({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
        tertiaryFormName: TertiaryFormNames.ReleaseTrackArtists,
        primaryFormId,
        secondaryFormId,
      });
      this.rootStore.revisedFormStore.comments.tertiaryComments = [
        ...this.rootStore.revisedFormStore.comments.tertiaryComments,
        ...extractComments(revisedForms),
      ];
      this.artists = revisedForms;
    } finally {
      this.relatedWorksLoading = false;
    }
  }

  @flow.bound
  *retrieveTrackLists(userId: string | null, primaryFormId: string, secondaryFormId: string) {
    try {
      this.rootStore.uiStore.setOverlayLoading(true);
      yield Promise.all([
        this.retrieveContributors(userId, primaryFormId, secondaryFormId),
        this.retrieveReleaseArtists(userId, primaryFormId, secondaryFormId),
        this.retrieveReleaseRelatedWorks(userId, primaryFormId, secondaryFormId),
      ]);
    } finally {
      this.rootStore.uiStore.resetLoading();
    }
  }

  @action.bound
  resetTrackLists = () => {
    this.contributors = [];
    this.artists = [];
    this.relatedWorks = [];
  };

  @action.bound
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
