import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomFormProvider } from "context";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { WorkIcon } from "assets/icons/24px";
import { FormBlock, Modal } from "components";
import { usePrimaryFormState } from "components/ReleaseForm";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import { PrimaryFormNames, SecondaryFormId, SecondaryFormNames, WorksForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import WorkForm from "../WorkForm";
import { defaultValues } from "../WorkForm/constants";
import { prepareRequest } from "../WorkForm/helpers";
import { schema } from "../WorkForm/validation";

import PublishersBlock from "./ListBlocks/PublishersBlock";
import SongwritersBlock from "./ListBlocks/SongwritersBlock";
import { getFormData } from "./helpers";
import { WorkProps } from "./types";
import { useWorkFormState } from "./useWorkFormState";

const Work: FC<WorkProps> = ({ work, successCb, closeModal }) => {
  const {
    revisedFormStore: {
      works: { retrieveWorkLists, resetWorkLists },
      comments: { resetTertiaryComments },
    },
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const { isReadOnly, isEditable } = usePrimaryFormState();
  const getSecondaryFormState = useWorkFormState();

  useEffect(() => {
    return () => {
      resetTertiaryComments();
    };
  }, []);

  const form = useForm<WorksForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid, dirtyFields },
  } = form;

  useEffect(() => {
    work.id && retrieveWorkLists(userId, releaseId!, work.id!);
    return () => {
      resetWorkLists();
    };
  }, [work?.id]);

  useEffect(() => {
    work?.id && reset(getFormData(work));
  }, [reset, work?.id]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      setOverlayLoading(true);
      const requestParams: SecondaryFormId = {
        userId,
        primaryFormId: releaseId!,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormId: work.id!,
        secondaryFormName: SecondaryFormNames.ReleaseWorks,
      };
      const requestData = prepareRequest(formData, dirtyFields);
      await revisedFormService.patchSecondaryForm(requestParams, requestData);
      reset(formData, { keepDirty: false });
      successCb?.(replaceEmptyAndUndefinedWithNull({ ...work, ...formData }));
      enqueueSnackbar("The composition has been successfully saved.");
    } finally {
      resetLoading();
    }
  });

  return (
    <CustomFormProvider {...getSecondaryFormState(work.id as string)}>
      <FormProvider {...form}>
        <Modal.ModalFullScreenTitle title="Add the Composition" closeModal={closeModal}>
          {!isReadOnly || isEditable ? (
            <Button variant="contained" size="medium" disabled={!isValid} onClick={onSubmit}>
              Save
            </Button>
          ) : null}
        </Modal.ModalFullScreenTitle>
        <Modal.ModalFullScreenContent>
          <Grid container flex={1} justifyContent="center" alignSelf="flex-start">
            <Grid item xs={10} md={8} display="flex" flexDirection="column" gap="16px">
              <FormBlock title="Composition Information" icon={<WorkIcon />}>
                <WorkForm extendedForm />
              </FormBlock>
              <SongwritersBlock work={work} />
              <PublishersBlock work={work} />
            </Grid>
          </Grid>
        </Modal.ModalFullScreenContent>
      </FormProvider>
    </CustomFormProvider>
  );
};

export default observer(Work);
