import { FC } from "react";
import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { useTertiaryListFormState } from "components/ReleaseForm/Hooks";
import { AddEditArtistForm } from "components/ReleaseForm/Shared";
import { prepareRequest } from "components/ReleaseForm/Shared/Artists/AddEditArtistForm/helpers";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  ReleaseArtistsForm,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
} from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import { AddEditArtistProps } from "./types";

const AddEditArtist: FC<AddEditArtistProps> = ({ track, record, successCallback, closeModal }) => {
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const formState = useTertiaryListFormState()({
    secondaryFormName: SecondaryFormNames.ReleaseTracks,
    tertiaryFormName: TertiaryFormNames.ReleaseTrackArtists,
    secondaryFormId: track.id as string,
    tertiaryFormId: record?.id || "",
    filterCommentsCb: ({ releaseTrackArtistId }) => releaseTrackArtistId === record?.id,
    updateCommentCb: (tertiaryFormId, c) => {
      return (comment) =>
        comment.releaseTrackArtistId === tertiaryFormId && comment.fieldName === c.fieldName ? { ...comment, ...c } : comment;
    },
    deleteCommentCb: (tertiaryFormId, fieldName) => {
      return (comment) => !(comment.releaseTrackArtistId === tertiaryFormId && comment.fieldName === fieldName);
    },
  });

  const handlePatchRequest = async (
    formData: ReleaseArtistsForm,
    requestParams: TertiaryFormId,
    dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
  ) => {
    const requestData = prepareRequest(formData, dirtyFields);
    await revisedFormService.patchTertiaryForm(requestParams, requestData);
    const { revisedForm } = await revisedFormService.getTertiaryFormId(requestParams);
    successCallback?.(revisedForm);
  };

  const handlePostRequest = async (formData: ReleaseArtistsForm, requestParams: TertiarySecondaryFormId) => {
    const { revisedForm } = await revisedFormService.postTertiaryForm(requestParams, replaceEmptyAndUndefinedWithNull(formData));
    successCallback?.(revisedForm);
  };

  const onSubmit =
    (dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>) => async (formData: ReleaseArtistsForm) => {
      if (!track.id || !track.releaseId) return;

      setOverlayLoading(true);

      const requestParams: TertiarySecondaryFormId = {
        userId,
        [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
        [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
        [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseTrackArtists,
        [ApiKeyFields.primaryFormId]: releaseId!,
        [ApiKeyFields.secondaryFormId]: track.id,
      };

      try {
        if (record) {
          await handlePatchRequest(formData, { ...requestParams, [ApiKeyFields.tertiaryFormId]: record.id! }, dirtyFields);
        } else {
          await handlePostRequest(formData, requestParams);
        }
        closeModal();
      } finally {
        resetLoading();
      }
    };

  return <AddEditArtistForm formState={formState} record={record} onSubmit={onSubmit} closeModal={closeModal} />;
};

export default observer(AddEditArtist);
