import React, { ReactNode, useEffect } from "react";
import styled from "@emotion/styled";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { CloseIcon } from "assets/icons/24px";
import { InputIconButton } from "components/HookFormControls/InputIconButton";

const DialogTitleStyled = styled(DialogTitle)`
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type GenericModalProps = {
  open: boolean;
  onClose?: () => void;
  title: string;
  subTitle?: string | ReactNode;
  content: React.ReactNode;
  actions?: React.ReactNode;
  dividers?: boolean;
  PaperProps?: DialogProps["PaperProps"];
  maxWidth?: DialogProps["maxWidth"];
  hideCloseIcon?: boolean;
  sx?: DialogProps["sx"];
  unmountCb?: () => void;
};

const GenericModal: React.FC<GenericModalProps> = ({
  open,
  onClose,
  title,
  subTitle,
  content,
  actions,
  dividers,
  PaperProps,
  maxWidth = "sm",
  hideCloseIcon,
  unmountCb,
  sx,
}) => {
  useEffect(() => {
    return () => {
      unmountCb?.();
    };
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} PaperProps={PaperProps} sx={sx}>
      <DialogTitleStyled>
        <Typography variant="h2" component="div">
          {title}
        </Typography>
        {subTitle ? <Typography variant="body1">{subTitle}</Typography> : null}
        {!hideCloseIcon || onClose ? (
          <InputIconButton
            size="small"
            sx={{
              position: "absolute",
              right: 25,
              top: 25,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </InputIconButton>
        ) : null}
      </DialogTitleStyled>
      <DialogContent dividers={dividers}>{content}</DialogContent>
      {!dividers ? <Divider /> : null}
      {actions ? <DialogActions sx={{ paddingRight: "24px" }}>{actions}</DialogActions> : null}
    </Dialog>
  );
};

export default GenericModal;
