import * as zod from "zod";

import { isScheduleEarlier, releaseDateSchema } from "components/ReleaseForm/ReleaseDate/validation";
import { genreTerritorySchema } from "components/ReleaseForm/ReleaseInformation/GenreTerritory/validation";
import { titleCoverArtSchema } from "components/ReleaseForm/ReleaseInformation/TitleCoverArtists/validation";
import { marketingServiceSchema } from "components/ReleaseForm/TrackManager/Tracks/validation";

export const schema = zod.intersection(
  zod.object({}).merge(titleCoverArtSchema).merge(genreTerritorySchema).merge(releaseDateSchema).merge(marketingServiceSchema),
  isScheduleEarlier,
);
