import { isNil } from "lodash";

import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";
import { GridRowParams } from "@mui/x-data-grid";

import { RowModifyActions } from "components/ReleaseForm";
import type { ReleaseColumnsProps } from "components/ReleaseForm/types";
import { ApiKeyFields, WorksForm } from "types";

export const getColumns = ({
  onEditClick,
  onDeleteClick,
  getRowComments,
  isReadOnly,
}: ReleaseColumnsProps<WorksForm>): GridColDef[] => [
  {
    field: ApiKeyFields.title,
    headerName: "Composition Title",
    sortable: false,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.status,
    headerName: "Composition Level Status",
    sortable: false,
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }) => {
      const isComplete = !isNil(row[ApiKeyFields.lyrics]);
      return (
        <Typography variant="body1" color={isComplete ? "text.success" : "text.warning"}>
          {isComplete ? "Completed" : "Not Completed"}
        </Typography>
      );
    },
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.iswc,
    headerName: "ISWC",
    sortable: false,
    flex: 1,
    minWidth: 150,
    renderCell: ({ value }) => <span>{value ?? "—"}</span>,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.actions,
    sortable: false,
    type: "actions",
    align: "right",
    disableColumnMenu: true,
    getActions: ({ row }: GridRowParams<WorksForm>) => {
      return [
        <RowModifyActions
          key={row.id}
          record={row}
          getRowComments={getRowComments}
          onDeleteClick={onDeleteClick}
          onEditClick={() => onEditClick(row)}
          isReadOnly={isReadOnly}
        />,
      ];
    },
  },
];
