import React, { useState } from "react";
import { FileRejection } from "react-dropzone";
import { Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";
import { observer } from "mobx-react";
import { useStores } from "stores";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

import { DropZone } from "components/FormControls";

import { handleFileErrors } from "./helpers";
import { DropZoneFieldType } from "./types";

const DropZoneField = <FieldType extends FieldValues>({
  fieldName,
  control,
  options,
  maxSizeLimit = void 0,
  dropFileText,
  readOnly,
  isDownloadAllowed,
  isDeleteAllowed,
  isDeletedState,
  formFile = true,
  onDeleteCallback,
  customValidation,
  fileAction,
  ...props
}: DropZoneFieldType<FieldType>) => {
  const {
    uiStore: { setProcessingLoading, resetLoading },
  } = useStores();
  const { setValue } = useFormContext();
  const [dropZoneError, setDropZoneError] = useState<string | null>(null);

  const updateAction = async (acceptedFiles: File[]) => {
    await fileAction?.(fieldName, { acceptedFiles, blob_action: "update" });
    setValue(fieldName, formFile ? acceptedFiles[0] : new Date().toString(), { shouldValidate: true, shouldDirty: false });
  };

  const downloadAction = async () => {
    if (isDownloadAllowed) {
      await fileAction?.(fieldName, { blob_action: "get" });
    }
  };

  const deleteAction = async () => {
    if (isDeleteAllowed) {
      const action = async () => {
        await fileAction?.(fieldName, { blob_action: "delete" });
      };

      if (onDeleteCallback) {
        await onDeleteCallback(action);
      } else {
        await action();
      }
    }
  };

  const onDrop = async <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0 && !options?.multiple) {
      const hasErrors = handleFileErrors(fileRejections, options, setDropZoneError, maxSizeLimit);
      if (hasErrors) {
        return;
      }
    }

    if (customValidation) {
      try {
        setProcessingLoading(true);
        const validationResult = await customValidation(acceptedFiles);
        if (!validationResult.valid) {
          setDropZoneError(validationResult.error);
          return;
        }
      } finally {
        resetLoading();
      }
    }

    setDropZoneError(null);
    if (!options?.multiple) {
      updateAction(acceptedFiles);
    }
  };

  return (
    <Controller
      name={fieldName as FieldPath<FieldType>}
      control={control}
      render={({ field: { onChange, ...fieldProps } }) => {
        return (
          <FormControl fullWidth>
            <DropZone
              {...props}
              {...fieldProps}
              dropFileText={dropFileText}
              isDownloadAllowed={isDownloadAllowed}
              isDeleteAllowed={isDeleteAllowed}
              onChange={(file) => onChange(file)}
              maxSizeLimit={maxSizeLimit}
              isDeletedState={isDeletedState}
              handleDownload={downloadAction}
              handleDelete={deleteAction}
              readOnly={readOnly}
              options={options}
              onDrop={onDrop}
            />
            {dropZoneError ? (
              <Typography variant="caption" color="error.main" sx={{ mt: "4px" }}>
                {dropZoneError}
              </Typography>
            ) : null}
          </FormControl>
        );
      }}
    />
  );
};

export default observer(DropZoneField);
