import { TERRITORY_VALUES } from "constants/release";
import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.territory]: TERRITORY_VALUES,
  [ApiKeyFields.schedules_at]: null,
  [ApiKeyFields.schedule_earlier]: false,
  releaseArtists: [],
  works: [],
  tracks: [],
};
