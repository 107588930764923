import { FC, useState } from "react";
import styled from "@emotion/styled";

import Alert, { alertClasses } from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { InfoIcon } from "assets/icons/14px";
import { CloseIcon } from "assets/icons/24px";

export const AlertStyled = styled(Alert)`
  display: flex;

  .${alertClasses.icon} {
    display: flex;
    align-items: center;

    svg path {
      fill: ${({ theme }) => theme.palette.text.header};
    }
  }

  &.${alertClasses.filledInfo} {
    .${alertClasses.icon} {
      svg path {
        fill: ${({ theme }) => theme.palette.icon};
      }
    }
  }

  .${alertClasses.message} {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0;
  }

  & .MuiButtonBase-root:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

const OptionFieldsAlert: FC = () => {
  const [isHidden, setHidden] = useState(false);

  const handleCloseClick = () => setHidden(true);

  return !isHidden ? (
    <AlertStyled variant="filled" severity="warning" icon={<InfoIcon />}>
      <Typography variant="button" textTransform="none" flex="1">
        <b>Tip:</b> All required fields are marked with{" "}
        <Typography component="span" color="error.main">
          *
        </Typography>{" "}
        and all other optional fields are marked as "(Optional)"
      </Typography>
      <IconButton onClick={handleCloseClick} size="medium">
        <CloseIcon />
      </IconButton>
    </AlertStyled>
  ) : null;
};

export default OptionFieldsAlert;
