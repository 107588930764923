import { Components } from "@mui/material/styles";

import { colors } from "../colors";

export const MuiAlert: Components["MuiAlert"] = {
  styleOverrides: {
    root: {
      minHeight: "62px",
    },
    filledInfo: {
      backgroundColor: colors.white,
    },
    filledWarning: {
      backgroundColor: colors.yellow312,
      border: `1px solid ${colors.orange618}`,
    },
  },
};
