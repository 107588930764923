import { FC } from "react";
import { useFormContext } from "react-hook-form";

import Grid from "@mui/material/Grid";

import { CheckboxField, FormBlockGridGap, TextField } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { ApiKeyFields, ReleaseArtistsForm } from "types";

import { FORM_LABELS, HELPER_TEXT, TEXT_FIELDS } from "./constants";
import { FormProps } from "./types";

const Form: FC<FormProps> = ({ isGeneralArtist }) => {
  const { control } = useFormContext<ReleaseArtistsForm>();

  return (
    <Grid container gap={FormBlockGridGap}>
      <FormGridFieldItem
        fieldName={ApiKeyFields.artistName}
        label={FORM_LABELS[ApiKeyFields.artistName]}
        helperText={HELPER_TEXT[ApiKeyFields.artistName]}
      >
        {(field) => <TextField {...field} control={control} />}
      </FormGridFieldItem>
      {!isGeneralArtist ? (
        <FormGridFieldItem fieldName={ApiKeyFields.isFeatured} label={FORM_LABELS[ApiKeyFields.isFeatured]} isOptionalField>
          {(fieldName) => <CheckboxField {...fieldName} control={control} label={HELPER_TEXT[ApiKeyFields.isFeatured]} />}
        </FormGridFieldItem>
      ) : null}
      {TEXT_FIELDS.map((fieldName) => (
        <FormGridFieldItem
          key={fieldName}
          fieldName={fieldName}
          label={FORM_LABELS[fieldName]}
          helperText={HELPER_TEXT[fieldName]}
          isOptionalField
        >
          {(field) => <TextField {...field} control={control} />}
        </FormGridFieldItem>
      ))}
    </Grid>
  );
};

export default Form;
