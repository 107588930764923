import React from "react";

import { MenuItem, SelectChangeEvent } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { Select } from "components/FormControls";

import { LoadingContainerStyled } from "./styled";
import { InfiniteSelectProps } from "./types";

const InfiniteSelect = <ValueType extends string | number>({
  value,
  options,
  loading,
  hasNextPage,
  onChange,
  sentryRef,
  ...props
}: InfiniteSelectProps<ValueType>) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedValue = event.target.value as ValueType;
    onChange(selectedValue);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: "40vh",
            overflowY: "auto",
          },
        },
      }}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      {loading || hasNextPage ? (
        <LoadingContainerStyled ref={sentryRef}>
          <CircularProgress size="24px" />
        </LoadingContainerStyled>
      ) : null}
      {!loading && !options.length && !hasNextPage && <LoadingContainerStyled>No options found.</LoadingContainerStyled>}
    </Select>
  );
};

export default InfiniteSelect;
