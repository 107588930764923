import { FC, useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CustomFormProvider } from "context";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Grid from "@mui/material/Grid";

import FormProgressBar from "components/FormProgressBar";
import { PageContainer, ProgressBarStyled } from "components/Layout";
import PageLeaveGuard from "components/PageLeaveGuard";
import { useReleaseForm } from "components/ReleaseForm";
import { useFetchComments, usePrimaryFormState } from "components/ReleaseForm";
import ReleaseFormHeader from "components/ReleaseForm/ReleaseFormHeader";
import { ROUTES } from "constants/routes";

import { progressBarConfig } from "./progressBarConfig";
import { ReleaseReviewParams } from "./types";

const Release: FC = () => {
  const {
    revisedFormStore: {
      release,
      comments: { primaryComments, secondaryComments },
      releaseComments: { comments },
    },
  } = useStores();
  const form = useReleaseForm();
  const navigate = useNavigate();
  const { userId, releaseId } = useParams<ReleaseReviewParams>();
  const { resetCommentsTotal, retrieveCommentState } = useFetchComments();

  useEffect(() => {
    releaseId && retrieveCommentState(release?.status);
    return resetCommentsTotal;
  }, [releaseId, release?.status]);

  const config = useMemo(
    () => progressBarConfig(ROUTES.RELEASE_ID(userId, releaseId), { ...comments, primaryComments, secondaryComments }),
    [userId, releaseId, comments["release-tracks"], comments["release-works"], primaryComments, secondaryComments],
  );

  const formState = usePrimaryFormState();

  const onBackClick = () => navigate(ROUTES.RELEASES);

  return (
    <FormProvider {...form}>
      <CustomFormProvider {...formState}>
        <PageLeaveGuard
          formRoutes={[
            ROUTES.RELEASE_ID(userId, releaseId),
            ROUTES.RELEASE_ID_GENRE(userId, releaseId),
            ROUTES.RELEASE_ID_WORKS(userId, releaseId),
            ROUTES.RELEASE_ID_TRACKS(userId, releaseId),
            ROUTES.RELEASE_ID_RELEASE_DATE(userId, releaseId),
          ]}
          shouldStayOnTheSamePage
        />
        <PageContainer noBottomSpacing>
          <ReleaseFormHeader onBackClick={onBackClick} isSubmitDisabled />
          <Grid container flex={1}>
            <Grid item md={3}>
              <ProgressBarStyled>
                <FormProgressBar config={config} comments={primaryComments} />
              </ProgressBarStyled>
            </Grid>
            <Grid item md={9} display="flex" flexDirection="column">
              <Outlet />
            </Grid>
          </Grid>
        </PageContainer>
      </CustomFormProvider>
    </FormProvider>
  );
};

export default observer(Release);
