import { useCallback, useEffect } from "react";
import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useStores } from "stores";
import * as zod from "zod";

import Typography from "@mui/material/Typography";

import { CheckboxField, ConfirmModal, DndOrderIndex } from "components";
import { FormGridItem } from "components/FormComponents/FormGridItems";
import { useFetchComments } from "components/ReleaseForm/Hooks";
import CopyEntity from "components/ReleaseForm/Shared/CopyEntity";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { releaseGeneralArtistService, revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  ReleaseArtists,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
  TracksForm,
} from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import AddEditArtist from "./AddEditArtist";

export const schema = zodResolver(
  zod.object({
    [ApiKeyFields.value]: zod.union([zod.string(), zod.number()]),
    [ApiKeyFields.isFeatured]: zod.boolean().nullable(),
  }),
);

export const useTrackArtists = (track: TracksForm) => {
  const {
    revisedFormStore: {
      tracks: { artists, artistsLoading },
    },
    uiStore: { setOverlayLoading, resetLoading, openModal },
  } = useStores();
  const { fetchTracksComments } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();

  const { control, setValue, watch } = useFormContext<{ artists: ReleaseArtists[] }>();
  const { fields } = useFieldArray({
    control,
    name: "artists",
    keyName: "_id",
  });

  useEffect(() => {
    artists && setValue("artists", artists, { shouldDirty: false });
  }, [artists]);

  const handleArtistsArrayChange = (data: ReleaseArtists) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [...fields, data];
    setValue("artists", newArray, { shouldDirty: false });
  };

  const handleChangeRows = useCallback(
    async (rows: ReleaseArtists[], { afterId, currentItemId }: DndOrderIndex) => {
      const prevValues = [...watch("artists")];
      try {
        setValue("artists", rows, { shouldDirty: false });
        const requestParams: TertiaryFormId = {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          secondaryFormName: SecondaryFormNames.ReleaseTracks,
          tertiaryFormName: TertiaryFormNames.ReleaseTrackArtists,
          primaryFormId: releaseId!,
          secondaryFormId: track.id!,
          tertiaryFormId: currentItemId,
        };
        await revisedFormService.reorderTertiaryForm(requestParams, { afterId });
      } catch {
        setValue("artists", prevValues, { shouldDirty: false });
      }
    },
    [setValue, track],
  );

  const handleDeleteArtist = async (record: ReleaseArtists, closeModal: () => void) => {
    try {
      setOverlayLoading(true);
      const requestParams: TertiaryFormId = {
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseTracks,
        tertiaryFormName: TertiaryFormNames.ReleaseTrackArtists,
        primaryFormId: releaseId!,
        secondaryFormId: track.id!,
        tertiaryFormId: record.id as string,
      };
      await revisedFormService.deleteTertiaryFormId(requestParams);
      await fetchTracksComments();
      closeModal();
      const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
      setValue("artists", updatedArray, { shouldDirty: false });
    } finally {
      resetLoading();
    }
  };

  const handleAddArtist = () => {
    openModal({
      component: (modalProps) => <AddEditArtist {...modalProps} track={track} successCallback={handleArtistsArrayChange} />,
    });
  };

  const handleEditArtist = (record: ReleaseArtists) => {
    openModal({
      component: (modalProps) => (
        <AddEditArtist {...modalProps} track={track} record={record} successCallback={handleArtistsArrayChange} />
      ),
    });
  };

  const handleDeleteClick = (record: ReleaseArtists) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the artist?"
          text={
            <Typography variant="body1">
              Remove <b>{record.artistName}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteArtist(record, modalProps.closeModal)}
        />
      ),
    });
  };

  const fetchArtist = async ({ offset, limit }: { offset: number; limit: number }) => {
    const response = await releaseGeneralArtistService.getGeneralArtistsList({
      release_general_artist_offset: offset,
      release_general_artist_limit: limit,
    });
    return response.releaseGeneralArtists;
  };

  const copyArtist = useCallback(
    async ({ value, isFeatured }: FieldValues, options: ReleaseArtists[]) => {
      if (!track.id || !releaseId) return;
      try {
        setOverlayLoading(true);
        const findRecord = options.find(({ id }) => id === value);
        if (findRecord) {
          const requestParams: TertiarySecondaryFormId = {
            userId: null,
            [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
            [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
            [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseTrackArtists,
            [ApiKeyFields.primaryFormId]: releaseId!,
            [ApiKeyFields.secondaryFormId]: track.id,
          };
          const { revisedForm } = await revisedFormService.postTertiaryForm(
            requestParams,
            replaceEmptyAndUndefinedWithNull({ ...findRecord, isFeatured }),
          );
          handleArtistsArrayChange(revisedForm);
        }
      } finally {
        resetLoading();
      }
    },
    [track, fields],
  );

  const handleCopyArtist = () => {
    openModal({
      component: (modalProps) => (
        <CopyEntity
          {...modalProps}
          fetchFunction={fetchArtist}
          mapFunction={(record) => ({ label: record.artistName!, value: record.id! })}
          modalTitle="Copy Artist"
          inputLabel="Select Artist"
          onSubmitCb={copyArtist}
          defaultValues={{ [ApiKeyFields.isFeatured]: null }}
          validationSchema={schema}
        >
          {({ control }) => (
            <FormGridItem label="Is this a featured artist?" isOptionalField>
              <CheckboxField
                control={control}
                fieldName={ApiKeyFields.isFeatured}
                label="By default this artist is primary. Check here if the artist is featured."
              />
            </FormGridItem>
          )}
        </CopyEntity>
      ),
      fullWidth: true,
    });
  };

  return {
    fields,
    artistsLoading,
    handleArtistsArrayChange,
    handleCopyArtist,
    handleChangeRows,
    handleDeleteArtist,
    handleAddArtist,
    handleEditArtist,
    handleDeleteClick,
  };
};
