import { useParams } from "react-router-dom";
import { useFormNavigation } from "hooks";

import ReleaseDate from "components/ReleaseForm/ReleaseDate";
import GenreTerritory from "components/ReleaseForm/ReleaseInformation/GenreTerritory";
import TitleCoverArt from "components/ReleaseForm/ReleaseInformation/TitleCoverArtists";
import AddTracks from "components/ReleaseForm/TrackManager/Tracks";
import AddWorks from "components/ReleaseForm/TrackManager/Works";
import { ROUTES } from "constants/routes";

import { ReleaseReviewParams } from "./types";
import { useReleaseService } from "./useReleaseService";

export { AdminTitleCoverArt, AdminGenreTerritory, AdminWorks, AdminTracks, AdminReleaseDate };

function AdminTitleCoverArt() {
  const { userId, releaseId } = useParams<ReleaseReviewParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    null,
    ROUTES.RELEASE_ID_GENRE(userId, releaseId),
    {
      prev: true,
      next: false,
    },
  );
  const { getPrimaryBlob } = useReleaseService();

  return (
    <TitleCoverArt
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      fileAction={getPrimaryBlob}
      isAdmin
    />
  );
}

function AdminGenreTerritory() {
  const { userId, releaseId } = useParams<ReleaseReviewParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.RELEASE_ID(userId, releaseId),
    ROUTES.RELEASE_ID_WORKS(userId, releaseId),
  );
  return (
    <GenreTerritory
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      isAdmin
    />
  );
}

function AdminWorks() {
  const { userId, releaseId } = useParams<ReleaseReviewParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.RELEASE_ID_GENRE(userId, releaseId),
    ROUTES.RELEASE_ID_TRACKS(userId, releaseId),
  );
  return (
    <AddWorks
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
    />
  );
}

function AdminTracks() {
  const { userId, releaseId } = useParams<ReleaseReviewParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.RELEASE_ID_WORKS(userId, releaseId),
    ROUTES.RELEASE_ID_RELEASE_DATE(userId, releaseId),
  );
  return (
    <AddTracks
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
    />
  );
}

function AdminReleaseDate() {
  const { userId, releaseId } = useParams<ReleaseReviewParams>();
  const { navigateBack, navigateNext, nextBtnDisabled, prevBtnDisabled } = useFormNavigation(
    ROUTES.RELEASE_ID_TRACKS(userId, releaseId),
    null,
    {
      prev: false,
      next: true,
    },
  );

  return (
    <ReleaseDate
      isRegistrationFlow
      nextBtnDisabled={nextBtnDisabled}
      prevBtnDisabled={prevBtnDisabled}
      onBackBtnClick={navigateBack}
      onNextBtnClick={navigateNext}
      isAdmin
    />
  );
}
