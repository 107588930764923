import { useCallback, useEffect } from "react";
import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import { Typography } from "@mui/material";

import { ConfirmModal, DndOrderIndex } from "components";
import { useFetchComments } from "components/ReleaseForm/Hooks";
import CopyEntity from "components/ReleaseForm/Shared/CopyEntity";
import { handleDuplicationError } from "components/ReleaseForm/TrackManager/helpers";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  Publishers,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
  WorksForm,
} from "types";

import AddEditPublisher from "./AddEditPublisher";

export const usePublishers = (work: WorksForm) => {
  const {
    revisedFormStore: {
      works: { publishers, publishersLoading },
    },
    uiStore: { setOverlayLoading, resetLoading, openModal },
  } = useStores();
  const { fetchWorksComments } = useFetchComments();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const { enqueueSnackbar } = useSnackbar();

  const { control, setValue, watch } = useFormContext<{ publishers: Publishers[] }>();
  const { fields } = useFieldArray({
    control,
    name: "publishers",
    keyName: "_id",
  });

  useEffect(() => {
    publishers && setValue("publishers", publishers, { shouldDirty: false });
  }, [publishers]);

  const handleSongwritersArrayChange = (data: Publishers) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [...fields, data];
    setValue("publishers", newArray, { shouldDirty: false });
  };

  const handleChangeRows = useCallback(
    async (rows: Publishers[], { afterId, currentItemId }: DndOrderIndex) => {
      const prevValues = [...watch("publishers")];
      try {
        setValue("publishers", rows, { shouldDirty: false });
        await revisedFormService.reorderTertiaryForm(
          {
            userId,
            primaryFormName: PrimaryFormNames.Releases,
            secondaryFormName: SecondaryFormNames.ReleaseWorks,
            tertiaryFormName: TertiaryFormNames.ReleasePublishers,
            primaryFormId: releaseId!,
            secondaryFormId: work.id!,
            tertiaryFormId: currentItemId,
          },
          { afterId },
        );
      } catch {
        setValue("publishers", prevValues, { shouldDirty: false });
      }
    },
    [setValue, work],
  );

  const handleDeleteSongwriter = async (record: Publishers, closeModal: () => void) => {
    try {
      setOverlayLoading(true);
      const requestParams: TertiaryFormId = {
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseWorks,
        tertiaryFormName: TertiaryFormNames.ReleasePublishers,
        primaryFormId: releaseId!,
        secondaryFormId: work.id!,
        tertiaryFormId: record.id as string,
      };
      await revisedFormService.deleteTertiaryFormId(requestParams);
      await fetchWorksComments();
      closeModal();
      const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
      setValue("publishers", updatedArray, { shouldDirty: false });
    } finally {
      resetLoading();
    }
  };

  const handleAddPublisher = () => {
    openModal({
      component: (modalProps) => <AddEditPublisher {...modalProps} work={work} successCallback={handleSongwritersArrayChange} />,
    });
  };

  const handleEditPublisher = (record: Publishers) => {
    openModal({
      component: (modalProps) => (
        <AddEditPublisher {...modalProps} work={work} record={record} successCallback={handleSongwritersArrayChange} />
      ),
    });
  };

  const handleDeleteClick = (record: Publishers) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the publisher?"
          text={
            <Typography variant="body1">
              Remove <b>{record.name}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteSongwriter(record, modalProps.closeModal)}
        />
      ),
    });
  };

  const fetchPublishers = useCallback(
    async ({ offset, limit }: { offset: number; limit: number }) => {
      const response = await revisedFormService.retrieveReleasePublishersOptions(
        { primaryFormId: releaseId! },
        { offset, limit, not_release_work_id: work.id!, order_by_field_name: ApiKeyFields.name },
      );
      return response.revisedForms;
    },
    [work],
  );

  const copySongwriter = useCallback(
    async ({ value }: FieldValues, options: Publishers[]) => {
      if (!work.id || !releaseId) return;
      try {
        setOverlayLoading(true);
        const findRecord = options.find(({ id }) => id === value);
        if (findRecord) {
          const requestParams: TertiarySecondaryFormId = {
            userId,
            [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
            [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseWorks,
            [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleasePublishers,
            [ApiKeyFields.primaryFormId]: releaseId!,
            [ApiKeyFields.secondaryFormId]: work.id,
          };
          const { revisedForm } = await revisedFormService.postTertiaryForm(requestParams, findRecord);
          handleSongwritersArrayChange(revisedForm);
        }
      } catch (error) {
        handleDuplicationError(error as AxiosApiError, enqueueSnackbar);
      } finally {
        resetLoading();
      }
    },
    [work, fields],
  );

  const handleCopyPublisher = () => {
    openModal({
      component: (modalProps) => (
        <CopyEntity
          {...modalProps}
          fetchFunction={fetchPublishers}
          mapFunction={(record) => ({ label: record.name!, value: record.id! })}
          modalTitle="Copy Publisher"
          inputLabel="Select Publisher"
          onSubmitCb={copySongwriter}
        />
      ),
      fullWidth: true,
    });
  };

  return {
    fields,
    publishersLoading,
    handleSongwritersArrayChange,
    handleCopyPublisher,
    handleChangeRows,
    handleDeleteSongwriter,
    handleAddPublisher,
    handleEditPublisher,
    handleDeleteClick,
  };
};
