import { makeObservable } from "mobx";
import { RootStore } from "stores";
import { TableStore } from "stores/_tableStore";

import { GridSortModel } from "@mui/x-data-grid";

import { releaseGeneralArtistService } from "services";
import { GeneralArtist, GeneralArtistListParams, GeneralArtistResponse } from "types";

export class ClientGeneralArtistsTable extends TableStore<GeneralArtistResponse, GeneralArtist, GeneralArtistListParams> {
  constructor(public rootStore: RootStore) {
    super();
    makeObservable(this);
  }

  getTableData = async (defaultSortModel?: GridSortModel) => {
    await this.retrieveTableData({
      service: releaseGeneralArtistService.getGeneralArtistsList,
      extractData: ({ numReleaseGeneralArtists, releaseGeneralArtists }: GeneralArtistResponse) => ({
        rows: releaseGeneralArtists,
        totalCount: numReleaseGeneralArtists,
      }),
      prepareGenericParams: (data) => {
        const { paginationModel, filters } = data;
        const release_general_artist_offset = paginationModel.page * paginationModel.pageSize;
        const release_general_artist_limit = paginationModel.pageSize;
        return {
          release_general_artist_offset,
          release_general_artist_limit,
          ...filters,
        };
      },
      defaultSortModel,
    });
  };
}
