import { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

import { DndTableGrid, LabelWithAsterisk, ReleaseTableListBlock } from "components";
import { usePrimaryFormState } from "components/ReleaseForm";
import { ArtistsEmptyTableMessage, getColumns } from "components/ReleaseForm/Shared/Artists";
import { ReleaseArtistsForm } from "types";

import { ARTIST_MAX_LIMIT } from "./constants";
import { BlockContainer } from "./styled";
import { useArtists } from "./useArtists";

const ArtistsBlock: FC = () => {
  const {
    revisedFormStore: {
      comments: { secondaryComments },
      releaseArtists: { isLoading },
    },
  } = useStores();
  const { fields, handleAddArtist, handleEditArtist, handleDeleteClick, handleChangeRows, handleCopyArtist } = useArtists();
  const { isReadOnly } = usePrimaryFormState();

  const getRowComments = (releaseArtist: ReleaseArtistsForm) =>
    secondaryComments.filter(({ releaseArtistId }) => releaseArtistId === releaseArtist.id);

  const columns = useMemo(
    () =>
      getColumns({
        onEditClick: handleEditArtist,
        onDeleteClick: handleDeleteClick,
        getRowComments,
        isReadOnly,
      }),
    [handleEditArtist],
  );

  const itemsCount = Boolean(fields.length) ? fields.length : null;

  return (
    <BlockContainer>
      <ReleaseTableListBlock
        title={<LabelWithAsterisk>Artist(s)</LabelWithAsterisk>}
        itemsCount={itemsCount}
        maxLimit={ARTIST_MAX_LIMIT}
        onAddClick={handleAddArtist}
        addBtnText="Add Artist"
        copyBtnText="Copy Artist"
        onCopyClick={handleCopyArtist}
        isLoading={isLoading}
        hideActionBtn={isReadOnly}
      >
        <DndTableGrid
          rows={fields}
          columns={columns}
          onOrderChange={handleChangeRows}
          slots={{ noRowsOverlay: ArtistsEmptyTableMessage }}
          loading={isLoading}
          isDragEnabled={!isReadOnly}
        />
      </ReleaseTableListBlock>
    </BlockContainer>
  );
};

export default observer(ArtistsBlock);
