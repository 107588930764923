import { axiosInstance } from "services";
import { GeneralArtistForm, GeneralArtistListParams, GeneralArtistResponse } from "types";

export function getGeneralArtistsList(params: GeneralArtistListParams): Promise<GeneralArtistResponse> {
  return axiosInstance.get(`/users/current/release-general-artists`, { params });
}

export function postGeneralArtists(releaseGeneralArtist: GeneralArtistForm) {
  return axiosInstance.post("/users/current/release-general-artists", { releaseGeneralArtist });
}

export function patchGeneralArtists(id: string, releaseGeneralArtist: GeneralArtistForm): Promise<string> {
  return axiosInstance.patch(`/users/current/release-general-artists/${id}`, { releaseGeneralArtist });
}

export function deleteGeneralArtists(id: string): Promise<string> {
  return axiosInstance.delete(`/users/current/release-general-artists/${id}`);
}
