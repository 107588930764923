import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Location, useBlocker } from "react-router-dom";
import { useConfirmBeforeUnload } from "hooks";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import GenericDialog from "components/GenericDialog";
import { RESOURCES_KEYS } from "i18n";

type Props = {
  formRoutes?: string[];
  shouldStayOnTheSamePage?: boolean;
};

const PageLeaveGuard: FC<Props> = ({ formRoutes, shouldStayOnTheSamePage }) => {
  const {
    formState: { isDirty, isSubmitting, errors },
  } = useFormContext();
  const {
    uiStore: { isOverlayLoading, isProcessingLoading, loadingText },
  } = useStores();
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);
  useConfirmBeforeUnload(isDirty || (isOverlayLoading && Boolean(loadingText)) || isProcessingLoading);

  const shouldBlockNavigation = (currentLocation: Location, nextLocation: Location) => {
    return formRoutes
      ? isDirty && !isSubmitting && !formRoutes.includes(nextLocation.pathname)
      : isDirty && currentLocation.pathname !== nextLocation.pathname;
  };

  const { state, proceed, reset } = useBlocker(({ currentLocation, nextLocation }) => {
    if ((!isEmpty(errors) && shouldStayOnTheSamePage) || isProcessingLoading) return true;
    return shouldBlockNavigation(currentLocation, nextLocation);
  });

  if (state === "blocked" && isProcessingLoading) {
    return (
      <GenericDialog
        open={isProcessingLoading}
        key={isProcessingLoading ? "dialog-open" : "dialog-closed"}
        title="Oops. It's not working now."
        PaperProps={{ sx: { width: "448px" } }}
        content={<Typography variant="body1">Please wait until the process is complete.</Typography>}
        actions={null}
        sx={{ zIndex: 9999 }}
        unmountCb={reset}
        hideCloseIcon
      />
    );
  }

  if (state === "blocked" && !isEmpty(errors)) {
    return (
      <GenericDialog
        open={true}
        title="Oops. It's not working now."
        onClose={reset}
        PaperProps={{ sx: { width: "448px" } }}
        content={<Typography variant="body1">To go back or forward, please correct the issue on the form.</Typography>}
        actions={
          <>
            <Button size="small" color="primary" variant="contained" onClick={reset}>
              Got It
            </Button>
          </>
        }
      />
    );
  }

  return state === "blocked" && !isProcessingLoading ? (
    <GenericDialog
      open={true}
      title="Signing off for now?"
      onClose={reset}
      PaperProps={{ sx: { width: "448px" } }}
      content={<Typography variant="body1">Please note, all unsaved data will be lost.</Typography>}
      actions={
        <>
          <Button size="small" variant="outlined" onClick={reset}>
            {t("cancel")}
          </Button>
          <Button size="small" variant="contained" onClick={proceed}>
            {t("confirm")}
          </Button>
        </>
      }
    />
  ) : null;
};

export default observer(PageLeaveGuard);
