import { forwardRef, useMemo } from "react";
import styled from "@emotion/styled";
import Omit from "lodash/omit";
import { CustomContentProps, SnackbarContent, SnackbarOrigin, useSnackbar } from "notistack";

import IconButton from "@mui/material/IconButton";
import Typography, { typographyClasses } from "@mui/material/Typography";

import { CloseIcon } from "assets/icons/24px";
import ToastLoggedOut from "assets/images/toastLoggedOut2x.png";
import ToastSuccess from "assets/images/toastSuccess2x.png";
import ToastWarning from "assets/images/toastWarning2x.png";

type IconType = "default" | "warning" | "logout";

declare module "notistack" {
  interface VariantOverrides {
    default: {
      title?: string;
      showClose?: boolean;
      iconType?: IconType;
    };
  }
}

export const anchorOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const IconMockStyled = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 20px;
  color: ${({ theme }) => theme.palette.text.disabled};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.surface};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  font-weight: 500;
  font-size: 9px;
  text-align: center;
`;

const SnackbarStyled = styled(SnackbarContent)(
  ({ theme }) => `
  min-height: 104px;
  width: 414px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${theme.palette.common.white};
  box-shadow: 0px 4px 16px 0px rgba(13, 28, 38, 0.15);

  .${typographyClasses.h5} {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  .${typographyClasses.root} {
    flex: 1;
  }

  .MuiIconButton-sizeSmall {
    align-self: start;
  }

  ${theme.breakpoints.only("xs")} {
    width: 100%;
    min-height: 50px;
    padding: 10px;
  }
`,
);

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props extends CustomContentProps {
  title?: string;
  showClose?: boolean;
  iconType?: IconType;
}

const DefaultSnackbar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { message, title, showClose, ...other } = Omit(props, ["id"]);

  const handleClose = () => {
    closeSnackbar(props.id);
  };

  const iconSrc = useMemo(() => {
    switch (props.iconType) {
      case "logout":
        return ToastLoggedOut;
      case "warning":
        return ToastWarning;
      default:
        return ToastSuccess;
    }
  }, [props.iconType]);

  return (
    <SnackbarStyled ref={ref} role="alert" {...other}>
      <IconMockStyled src={iconSrc} />
      <TextBlock>
        {title ? <Typography variant="h5">{title}</Typography> : null}
        <Typography variant="body1">{message}</Typography>
      </TextBlock>
      {showClose ? (
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      ) : null}
    </SnackbarStyled>
  );
});

DefaultSnackbar.displayName = "DefaultSnackbar";

export default {
  default: DefaultSnackbar,
};
