import { BlobDownloadOptions, BlockBlobClient } from "@azure/storage-blob";
import { useStores } from "stores";

import { revisedFormService } from "services";
import { ApiKeyFields, FileOptionProps, PrimaryFormNames } from "types";
import { onDownloadFile } from "utils";

export const useDistributionService = () => {
  const {
    qualificationStore: { qualificationForm },
    revisedFormStore: { formId, deleteDistributionLegalFile },
    uiStore: { onUploadingProgress, setProcessingLoading, resetLoading },
  } = useStores();

  const getDistrUserIdBlobLvl1 = async (field_name: ApiKeyFields, options: FileOptionProps) => {
    try {
      const { blob_action } = options;
      const primaryFormId = formId;
      if (!qualificationForm || !primaryFormId) return;

      const { id } = qualificationForm;

      if (blob_action === "delete") {
        setProcessingLoading(true, "Removing the file…");
        await revisedFormService.deletePrimaryBlob({
          userId: id,
          primaryFormName: PrimaryFormNames.DistributionLegals,
          primaryFormId,
          field_name,
        });
        deleteDistributionLegalFile(field_name);
      } else {
        setProcessingLoading(true, "Downloading the form…");
        const { blobFieldSasUrl } = await revisedFormService.getPrimaryBlob({
          userId: id,
          primaryFormName: PrimaryFormNames.DistributionLegals,
          primaryFormId,
          field_name,
          blob_action,
        });

        if (blob_action === "get") {
          const blobClient = new BlockBlobClient(blobFieldSasUrl);
          const properties = await blobClient.getProperties();
          const totalBytes = properties.contentLength;
          const downloadOptions: BlobDownloadOptions = {
            onProgress: (ev) => onUploadingProgress(ev.loadedBytes, totalBytes!),
          };
          const response = await blobClient.download(void 0, void 0, downloadOptions);
          const blob = await response.blobBody;
          if (!blob) {
            throw new Error("Failed to download blob");
          }
          const file = window.URL.createObjectURL(blob);
          onDownloadFile({ title: blob.size.toString(), file, fileFormat: "pdf" });
        }
      }
    } finally {
      resetLoading();
    }
  };

  return { getDistrUserIdBlobLvl1 };
};
