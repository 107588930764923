import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import { DeleteIcon, ReviewIcon } from "assets/icons/24px";
import {
  ConfirmModal,
  FormBarContainer,
  HeaderBtnContainerStyled,
  HeaderContainer,
  HeaderDetailInfo,
  ReleaseStatusChip,
} from "components";
import { ConditionalTooltip } from "components";
import { usePrimaryFormState } from "components/ReleaseForm";
import { areAllTracksComplete, areAllWorksComplete } from "components/ReleaseForm/helpers";
import { ROUTES } from "constants/routes";
import { RESOURCES_KEYS } from "i18n";
import { revisedFormService } from "services";
import { ApiKeyFields, CommentStatus, PrimaryFormId, PrimaryFormNames } from "types";

import { ReleaseUrlParams } from "../types";

import { prepareReleaseFormRequest } from "./helpers";
import ReviewReleaseModal from "./ReviewReleaseModal";
import { ReleaseForm, ReleaseFormHeaderProps } from "./types";

const DeleteIconStyled = styled(DeleteIcon)`
  path {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;

const ReleaseFormHeader: FC<ReleaseFormHeaderProps> = ({ onBackClick, isSubmitDisabled }) => {
  const [isFormSaved, setFormSaved] = useState<boolean>(true);
  const formContext = useFormContext<ReleaseForm>();
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);
  const {
    revisedFormStore: {
      releaseComments: { isAdminComments, isClientComments },
      comments: { primaryComments, secondaryComments },
      release,
    },
    notificationStore: { getCurrentNotifications },
    uiStore: { setOverlayLoading, openModal, resetLoading },
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const { isReadOnly, isEditable, isAdmin } = usePrimaryFormState();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    watch,
    reset,
    handleSubmit,
    formState: { dirtyFields, isDirty, isValid, errors },
  } = formContext;

  useEffect(() => {
    const subscription = watch(() => {
      setFormSaved(false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSave = async () => {
    if (!releaseId) return;
    const requestParams: PrimaryFormId = {
      userId,
      primaryFormName: PrimaryFormNames.Releases,
      primaryFormId: releaseId,
    };
    const requestData = prepareReleaseFormRequest(watch(), dirtyFields);
    await revisedFormService.patchPrimaryForm(requestParams, requestData);
    reset(watch(), { keepDirty: false });
  };

  const handleSaveClick = async (shouldNotify: boolean) => {
    try {
      setOverlayLoading(true, "Saving the form…");
      await handleSave();
      shouldNotify && enqueueSnackbar("The form has been successfully saved.");
    } finally {
      setOverlayLoading(false);
    }
  };

  const handleSaveClickCallback = async (shouldNotify: boolean) => {
    await handleSaveClick(shouldNotify);
    setFormSaved(true);
  };

  useEffect(() => {
    if (!isFormSaved && isDirty && isEmpty(errors)) {
      handleSaveClickCallback(false);
    }
  }, [location.pathname, errors]);

  const onClickSave = () => handleSaveClickCallback(true);

  const onDeleteRelease = async (closeModal: () => void) => {
    if (!releaseId) return;
    try {
      setOverlayLoading(true, "Removing the release…");
      await revisedFormService.deletePrimaryFormId({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        primaryFormId: releaseId,
      });
      closeModal();
      enqueueSnackbar("The release has been successfully removed.");
      navigate(ROUTES.ROOT);
    } finally {
      resetLoading();
    }
  };

  const onDeleteReleaseClick = () => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the release?"
          text="The release cannot be restored once it has been removed."
          onConfirmClick={() => onDeleteRelease(modalProps.closeModal)}
        />
      ),
    });
  };

  const onSubmit = handleSubmit(async (formData) => {
    if (!releaseId) return;
    try {
      setOverlayLoading(true, "Validating the release…");
      await handleSave();
      const requestParams: PrimaryFormId = {
        userId,
        primaryFormId: releaseId,
        primaryFormName: PrimaryFormNames.Releases,
      };
      const requestData = prepareReleaseFormRequest(formData, dirtyFields);
      await revisedFormService.submitForm(requestParams, requestData);
      await getCurrentNotifications(void 0, void 0, true);
      navigate(ROUTES.DASHBOARD);
      if (!isAdmin) {
        enqueueSnackbar("The release has been successfully submitted for revision.");
      }
    } finally {
      resetLoading();
    }
  });

  const handleReviewClick = () => {
    openModal({
      component: (modalProps) => (
        <FormProvider {...formContext}>
          <ReviewReleaseModal {...modalProps} />
        </FormProvider>
      ),
    });
  };

  const hasActiveAdminComment = useMemo(
    () => [...primaryComments, ...secondaryComments].some(({ status, forAdmin }) => status === CommentStatus.ACTIVE && forAdmin),
    [primaryComments, secondaryComments],
  );

  const isClientCommentsResolved = useMemo(
    () =>
      [...primaryComments, ...secondaryComments].every(
        ({ status, forAdmin }) => status === CommentStatus.RESOLVED && forAdmin === false,
      ),
    [primaryComments, secondaryComments],
  );

  const isFormErrors = Boolean(Object.keys(errors).length > 0);
  const watchTitle = watch(ApiKeyFields.title) || "Release title";
  const isTracksCompleted = areAllTracksComplete(watch("tracks"));
  const isWorksCompleted = areAllWorksComplete(watch("works"));

  const userSubmitBtnDisabled =
    !isValid || !isTracksCompleted || !isWorksCompleted || isSubmitDisabled || isClientComments || !isClientCommentsResolved;

  return (
    <FormBarContainer title={watchTitle} handleBackClick={onBackClick} noMarginBottom>
      <HeaderContainer>
        <HeaderDetailInfo title="Current Status" value={release?.status ? <ReleaseStatusChip status={release.status} /> : null} />
        {!isReadOnly || isEditable ? (
          <>
            <Divider orientation="vertical" />
            <HeaderBtnContainerStyled>
              <Button
                variant="outlined"
                size={isAdmin ? "small" : "medium"}
                disabled={!isDirty || isFormErrors}
                onClick={onClickSave}
              >
                {t("save")}
              </Button>
              {isAdmin ? (
                <ConditionalTooltip
                  title="To review the release, please resolve or remove all admin comments."
                  isTooltipVisible={isAdminComments || hasActiveAdminComment}
                >
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<ReviewIcon />}
                    disabled={!isValid || isAdminComments || hasActiveAdminComment}
                    onClick={handleReviewClick}
                  >
                    Review
                  </Button>
                </ConditionalTooltip>
              ) : null}
              {!isAdmin ? (
                <Button variant="contained" size="medium" disabled={userSubmitBtnDisabled} onClick={onSubmit}>
                  {t("submit")}
                </Button>
              ) : null}
            </HeaderBtnContainerStyled>
            {!isAdmin ? (
              <>
                <Divider orientation="vertical" />
                <IconButton size="small" onClick={onDeleteReleaseClick}>
                  <DeleteIconStyled />
                </IconButton>
              </>
            ) : null}
          </>
        ) : null}
      </HeaderContainer>
    </FormBarContainer>
  );
};

export default observer(ReleaseFormHeader);
