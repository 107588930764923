import * as zod from "zod";

import { MAX_LENGTH_INPUT, MIN_LEN_ERR_TEXT, REQUIRED_ISWC, SELECT_ERR_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

export const defaultSchemaFields = {
  [ApiKeyFields.title]: zod
    .string({ invalid_type_error: MIN_LEN_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.language]: zod.string({ invalid_type_error: SELECT_ERR_TEXT }).min(1, { message: SELECT_ERR_TEXT }),
  [ApiKeyFields.iswc]: zod // T-345246800-1
    .union([zod.string(), zod.null()])
    .optional()
    .default(null)
    .transform((value) => (value === null ? "" : value))
    .refine((value) => value === "" || /^T-\d{9}-\d$/.test(value), {
      message: REQUIRED_ISWC,
    }),
};

export const schema = zod.object(defaultSchemaFields);
