import React, { FC } from "react";
import styled from "@emotion/styled";
import { useStores } from "stores";

import Divider from "@mui/material/Divider";

import UserStatusChip from "components/UserStatusChip";
import { EN_DATE_FORMAT } from "constants/options";
import { DistributionTier } from "types";
import { utcToFormat } from "utils";

import { HeaderDetailInfo } from "./HeaderDetailInfo";

export const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  .MuiDivider-root {
    height: 40px;
  }
`;

export const HeaderBtnContainerStyled = styled.div`
  display: flex;
  gap: 16px;

  .MuiButton-root {
    margin-right: 0 !important;
  }
`;

const QualificationDetailsHeader: FC<{
  showTier?: boolean;
}> = ({ showTier }) => {
  const {
    qualificationStore: { qualificationForm },
  } = useStores();

  if (!qualificationForm) {
    return null;
  }

  const { status, distributionTier } = qualificationForm;
  const { createdAt } = qualificationForm.artists[0];

  return (
    <React.Fragment>
      <HeaderDetailInfo title="Created" value={utcToFormat(createdAt, EN_DATE_FORMAT)} />
      {showTier ? <HeaderDetailInfo title="Tier" value={distributionTier ? DistributionTier[distributionTier] : "-"} /> : null}
      <HeaderDetailInfo title="Current Status" value={<UserStatusChip status={status} />} />
      <Divider orientation="vertical" />
    </React.Fragment>
  );
};

export default QualificationDetailsHeader;
