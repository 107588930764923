import { action, makeAutoObservable, observable } from "mobx";
import { RootStore } from "stores";

import { ReleaseArtists } from "types";

export class ReleaseArtistsStore {
  @observable releaseArtistsList: ReleaseArtists[] = [];

  @observable isLoading: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  @action.bound
  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
