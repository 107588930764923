import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";

import { FeaturedArtistIcon } from "assets/icons/14px";
import { RowModifyActions } from "components/ReleaseForm";
import type { ReleaseColumnsProps } from "components/ReleaseForm/types";
import { ApiKeyFields, ReleaseArtists } from "types";

export const getColumns = ({
  onEditClick,
  onDeleteClick,
  getRowComments,
  isReadOnly,
  isGeneralArtists,
}: ReleaseColumnsProps<ReleaseArtists>): GridColDef<ReleaseArtists>[] => [
  ...((!isGeneralArtists
    ? [
        {
          field: ApiKeyFields.isFeatured,
          headerName: "",
          sortable: false,
          disableColumnMenu: true,
          width: 14,
          renderCell: ({ value }) => {
            return value ? (
              <Tooltip title="Featured Artist" placement="top-start" arrow>
                <FeaturedArtistIcon />
              </Tooltip>
            ) : null;
          },
        },
      ]
    : []) as GridColDef<ReleaseArtists>[]),
  {
    field: ApiKeyFields.artistName,
    headerName: "Artist Name",
    sortable: false,
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: ApiKeyFields.actions,
    sortable: false,
    type: "actions",
    disableColumnMenu: true,
    getActions: ({ row }) => {
      return [
        <RowModifyActions
          key={row.id}
          record={row}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          getRowComments={getRowComments}
          isReadOnly={isReadOnly}
        />,
      ];
    },
  },
];
