import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomFormProvider } from "context";
import { observer } from "mobx-react";

import Button from "@mui/material/Button";

import { Modal } from "components";
import { ReleaseArtistsForm } from "types";

import Form from "./Form/Form";
import { defaultValues } from "./constants";
import { AddEditArtistFormProps } from "./types";
import { schema } from "./validation";

const AddEditArtistForm: FC<AddEditArtistFormProps> = ({ closeModal, record, formState, onSubmit }) => {
  const form = useForm<ReleaseArtistsForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isValid, dirtyFields },
  } = form;

  useEffect(() => {
    record && reset(record);
  }, [record]);

  const handleSubmitForm = handleSubmit(onSubmit(dirtyFields));

  const modalContent = (
    <FormProvider {...form}>
      <Modal.ModalTitle title={`${record ? "Edit" : "Add"} Artist`} closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <Form />
      </Modal.ModalContent>
      {!formState.isReadOnly || formState.isEditable ? (
        <Modal.ModalActions>
          <Button size="small" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="small" variant="contained" disabled={!isValid || !isDirty} onClick={handleSubmitForm}>
            {record ? "Edit" : "Add"}
          </Button>
        </Modal.ModalActions>
      ) : null}
    </FormProvider>
  );

  if (record) {
    return <CustomFormProvider {...formState}>{modalContent}</CustomFormProvider>;
  }

  return modalContent;
};

export default observer(AddEditArtistForm);
