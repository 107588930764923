import { FC, ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import { ContactInfoOrangeIcon } from "assets/icons/24px";
import { FormBlock, FormBlockGridGap } from "components/FormComponents";
import { FormGridItem } from "components/FormComponents/FormGridItems";
import { PhoneField, TextField } from "components/HookFormControls";
import { RESOURCES_KEYS } from "i18n";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT, USER_DATA_FIELDS } from "../constants";

interface Props {
  isEmailReadOnly?: boolean;
  isPhoneHidden?: boolean;
  blockTitle?: string;
  blockIcon?: ReactNode;
  children?: React.ReactNode;
  readOnly?: boolean;
}

const ContactInformation: FC<Props> = ({ isEmailReadOnly, isPhoneHidden, blockTitle, blockIcon, children, readOnly }) => {
  const { control } = useFormContext();
  const { t } = useTranslation([RESOURCES_KEYS.QUALIFICATION]);

  const defaultFieldFormProps = (fieldName: ApiKeyFields) => ({
    fieldName,
    control: control,
    readOnly,
  });

  return (
    <FormBlock title={blockTitle ?? t(`contactInfo.yourContactInfo`)} icon={blockIcon ?? <ContactInfoOrangeIcon />}>
      <Grid container gap={FormBlockGridGap}>
        {USER_DATA_FIELDS.map((fieldName) => (
          <FormGridItem label={t(FORM_LABELS[fieldName])} key={fieldName}>
            <TextField {...defaultFieldFormProps(fieldName)} />
          </FormGridItem>
        ))}

        {!isPhoneHidden ? (
          <FormGridItem label={t(FORM_LABELS[ApiKeyFields.phone])} helperText={HELPER_TEXT[ApiKeyFields.phone]}>
            <PhoneField {...defaultFieldFormProps(ApiKeyFields.phone)} />
          </FormGridItem>
        ) : null}

        <FormGridItem label={t(FORM_LABELS[ApiKeyFields.email])}>
          <TextField {...defaultFieldFormProps(ApiKeyFields.email)} readOnly={isEmailReadOnly} />
        </FormGridItem>
        {children}
      </Grid>
    </FormBlock>
  );
};

export default ContactInformation;
