import { FC, useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CustomFormProvider } from "context/CustomFormContext";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Grid from "@mui/material/Grid";

import { PageContainer, ProgressBarStyled } from "components";
import FormProgressBar from "components/FormProgressBar";
import PageLeaveGuard from "components/PageLeaveGuard";
import { usePrimaryFormState } from "components/ReleaseForm";
import { useReleaseForm } from "components/ReleaseForm";
import { useFetchComments } from "components/ReleaseForm/Hooks";
import ReleaseFormHeader from "components/ReleaseForm/ReleaseFormHeader";
import { ROUTES } from "constants/routes";

import { progressBarConfig } from "./progressBarConfig";

const Release: FC = () => {
  const {
    notificationStore: { getCurrentNotifications },
    revisedFormStore: {
      comments: { primaryComments, secondaryComments },
      releaseComments: { comments },
      release,
    },
  } = useStores();
  const form = useReleaseForm();
  const { releaseId } = useParams();
  const navigate = useNavigate();
  const { resetCommentsTotal, retrieveCommentState } = useFetchComments();

  useEffect(() => {
    getCurrentNotifications(void 0, void 0, true, true);
    releaseId && retrieveCommentState(release?.status);
    return resetCommentsTotal;
  }, [releaseId, release?.status]);

  const onBackClick = () => navigate(ROUTES.DASHBOARD);

  const config = useMemo(
    () => progressBarConfig(ROUTES.CLIENT_DRAFT_RELEASE_ID(releaseId), { ...comments, primaryComments, secondaryComments }),
    [releaseId, comments["release-tracks"], comments["release-works"], primaryComments, secondaryComments],
  );
  const formState = usePrimaryFormState();

  return (
    <FormProvider {...form}>
      <CustomFormProvider {...formState}>
        <PageLeaveGuard
          formRoutes={[
            ROUTES.CLIENT_DRAFT_RELEASE_ID(releaseId),
            ROUTES.CLIENT_DRAFT_RELEASE_ID_GENRE(releaseId),
            ROUTES.CLIENT_DRAFT_RELEASE_ID_WORKS(releaseId),
            ROUTES.CLIENT_DRAFT_RELEASE_ID_TRACKS(releaseId),
            ROUTES.CLIENT_DRAFT_RELEASE_ID_RELEASE_DATE(releaseId),
          ]}
          shouldStayOnTheSamePage
        />
        <PageContainer noBottomSpacing>
          <ReleaseFormHeader onBackClick={onBackClick} />
          <Grid container flex={1}>
            <Grid item md={3}>
              <ProgressBarStyled>
                <FormProgressBar config={config} comments={primaryComments} isClientReview />
              </ProgressBarStyled>
            </Grid>
            <Grid item md={9} display="flex" flexDirection="column">
              <Outlet />
            </Grid>
          </Grid>
        </PageContainer>
      </CustomFormProvider>
    </FormProvider>
  );
};

export default observer(Release);
